// TopBar.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FiMenu, FiX } from 'react-icons/fi';

// Styled components
const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #062868;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 15s5px;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavMenu = styled.div`
  display: flex;
  gap: 1.5rem;

  a {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s;

    &:hover {
      color: #1e90ff;
    }
  }

  // Hide nav links on small screens
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileMenuIcon = styled.div`
  display: none;
  cursor: pointer;

  // Show the mobile menu icon on small screens
  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 100%;
  right: 1rem;
  background-color: #333;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;

  a {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s;

    &:hover {
      color: #1e90ff;
    }
  }
`;


const TopBar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  return (
    <TopBarContainer>
      <Logo>99INN's</Logo>
      <NavMenu>
        <a href="#home">Home</a>
        <a href="#features">Privacy</a>
        <a href="#pricing">Terms</a>
        <a href="#contact">Contact</a>
      </NavMenu>
      <MobileMenuIcon onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
      </MobileMenuIcon>
      {isMobileMenuOpen && (
        <MobileMenu>
          <a href="#home" onClick={toggleMobileMenu}>Home</a>
          <a href="#features" onClick={toggleMobileMenu}>Privacy</a>
          <a href="#pricing" onClick={toggleMobileMenu}>Terms</a>
          <a href="#contact" onClick={toggleMobileMenu}>Contact</a>
        </MobileMenu>
      )}
    </TopBarContainer>
  );
};

export default TopBar;
