import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components for the modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    background-color: #c82333;
  }
`;

const FormField = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const EnquiryFormModal = ({ isOpen, closeModal }) => {
  const [vehicle, setVehicle] = useState('');
  const [date, setDate] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log({ vehicle, date, name, number });
    closeModal();  // Close the modal after submission
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={closeModal}>X</CloseButton>
        <h3>Enquiry Form</h3>
        <form onSubmit={handleSubmit}>
          <FormField>
            <Label>Select Vehicle</Label>
            <Select value={vehicle} onChange={(e) => setVehicle(e.target.value)} required>
              <option value="">Select</option>
              <option value="Maruti Alto">Maruti Alto</option>
              <option value="Swift Dzire">Swift Dzire</option>
              <option value="Maruti Ertiga">Maruti Ertiga</option>
              <option value="Toyota Innova">Toyota Innova</option>
            </Select>
          </FormField>
          <FormField>
            <Label>Select Date</Label>
            <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
          </FormField>
          <FormField>
            <Label>Your Name</Label>
            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          </FormField>
          <FormField>
            <Label>Your Phone Number</Label>
            <Input type="tel" value={number} onChange={(e) => setNumber(e.target.value)} required />
          </FormField>
          <SubmitButton type="submit">Submit Enquiry</SubmitButton>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default EnquiryFormModal;
