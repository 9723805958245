// CabShowcase.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FiPhoneCall, FiMail, FiStar } from 'react-icons/fi';
import EnquiryFormModal from './EnquiryFormModal';

// Styled components for layout
const ShowcaseContainer = styled.div`
  padding: 2rem;
  background-color: #f8f9fa;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
`;

const Card = styled.div`
  width: 350px;
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;

const CardBody = styled.div`
  padding: 1.5rem;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StarIcon = styled(FiStar)`
  color: #f5b50a;
  margin-right: 2px;
`;

const CarName = styled.h3`
  color: #333;
  margin: 0.5rem 0;
  font-size: 1.25rem;
`;

const Details = styled.p`
  color: #555;
  margin: 0.5rem 0;
  font-size: 0.9rem;
`;

const Fare = styled.p`
  color: #d9534f;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 1rem;
`;

const SightseeingList = styled.ul`
  padding-left: 1rem;
  margin: 0.5rem 0;
  color: #666;
  font-size: 0.85rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 18px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;

  &:hover {
    background-color: #333;
  }

  &.call {
    background-color: #28a745;
  }

  &.enquiry {
    background-color: #007bff;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const CabShowcase = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const cabData = [
    {
      name: "MARUTI ALTO",
      image: "https://manaliholidaypackage.com/wp-content/uploads/2024/09/Maruti-Alto.jpg",
      type: "HatchBack",
      capacity: "4 Passengers",
      duration: "03 Days",
      fare: "Rs. 4500/-",
      rating: 4,
      sightseeing: [
        "Hidimba Temple, Tibetan Monastery, Van Vihar, Club House, Mall Road & Vashisht",
        "Solang Valley, Nehru Kund & Fun Valley",
        "Kullu, Manikaran, Kasol Valley, Mata Vaishno Devi Temple, Paragliding Point, River Rafting Point, Gurudwara Sahib & Parvati Valley",
      ],
    },
    {
      name: "SWIFT DZIRE",
      image: "https://manaliholidaypackage.com/wp-content/uploads/2024/10/swift-dzire-11.jpg",
      type: "Sedan",
      capacity: "4 Passengers",
      duration: "03 Days",
      fare: "Rs. 5500/-",
      rating: 5,
      sightseeing: [
        "Hidimba Temple, Tibetan Monastery, Van Vihar, Club House, Mall Road & Vashisht",
        "Solang Valley, Nehru Kund & Fun Valley",
        "Kullu, Manikaran, Kasol Valley, Mata Vaishno Devi Temple, Paragliding Point, River Rafting Point, Gurudwara Sahib & Parvati Valley",
      ],
    },
    {
      name: "MARUTI ERTIGA",
      image: "https://manaliholidaypackage.com/wp-content/uploads/2024/10/Maruti-Ertiga-11.jpg",
      type: "SUV",
      capacity: "7 Passengers",
      duration: "03 Days",
      fare: "Rs. 7500/-",
      rating: 4,
      sightseeing: [
        "Hidimba Temple, Tibetan Monastery, Van Vihar, Club House, Mall Road & Vashisht",
        "Solang Valley, Nehru Kund & Fun Valley",
        "Kullu, Manikaran, Kasol Valley, Mata Vaishno Devi Temple, Paragliding Point, River Rafting Point, Gurudwara Sahib & Parvati Valley",
      ],
    },
  ];

  return (
    <ShowcaseContainer>
      <Title>CAR RENTAL & TAXI SERVICE</Title>
      <Description>
        Book your favourite car with Car Rental & Taxi Service and travel comfortably in the valley to enjoy the scenic beauty of Himalayas.
      </Description>
      <CardContainer>
        {cabData.map((cab, index) => (
          <Card key={index}>
            <CardImage src={cab.image} alt={cab.name} />
            <CardBody>
              <Rating>
                {[...Array(cab.rating)].map((_, idx) => (
                  <StarIcon key={idx} />
                ))}
              </Rating>
              <CarName>{cab.name}</CarName>
              <div style={{ backgroundColor:'#f5f5f5', display:'flex', width:'70%', justifyContent:'center', borderRadius:12, margin:10 }}>
                <Details>Type: {cab.type}</Details>
              </div>
              <div style={{ backgroundColor:'#f5f5f5', display:'flex', width:'70%', justifyContent:'center', borderRadius:12, margin:10 }}>
                <Details>Capacity: {cab.capacity}</Details>
              </div>
              <div style={{ backgroundColor:'#f5f5f5', display:'flex', width:'70%', justifyContent:'center', borderRadius:12, margin:10 }}>
                <Details>Duration: {cab.duration}</Details>
              </div>
              <SightseeingList>
                {cab.sightseeing.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </SightseeingList>
              <div style={{ backgroundColor:'#f2e2e1', display:'flex', width:'90%', justifyContent:'center', borderRadius:12, margin:10 }}>
                <Fare>TAXI FARE: {cab.fare}</Fare>
              </div>
              <ButtonContainer>
                <Button href="tel:+911234567890" className="call">
                  <FiPhoneCall size={16} /> Call Now
                </Button>
                <Button onClick={openModal} className="enquiry">
                  <FiMail size={16} /> Enquiry
                </Button>
              </ButtonContainer>
            </CardBody>
          </Card>
        ))}
      </CardContainer>
      <EnquiryFormModal isOpen={modalOpen} closeModal={closeModal} />
    </ShowcaseContainer>
  );
};

export default CabShowcase;
